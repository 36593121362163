<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Website</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="openModal">Add Website</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns">
                  <template v-slot:cell(action)="data">
                    <div class="text-center">
                      <b-button variant="info" size="sm" @click="edit(data.item)">
            <i class="fas fa-pencil-alt"></i> <!-- Edit font icon -->
          </b-button>
          <b-button variant="danger" size="sm" @click="remove(data.item.public_id)">
            <i class="fas fa-trash-alt"></i> <!-- Delete font icon -->
          </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- Add/Edit Modal -->
    <b-modal v-model="modalOpen" :title="editMode ? 'Edit Country' : 'Add New Country'" ok-title="Save" @ok="submitForm">
      <b-form @submit.stop.prevent="submitForm">
        <b-form-group label="Name" label-for="nameInput">
          <b-form-input id="nameInput" v-model="newWebsite.name" required></b-form-input>
        </b-form-group>
        <b-form-group label="Website" label-for="websiteInput">
          <b-form-input id="websiteInput" v-model="newWebsite.website" required></b-form-input>
        </b-form-group>
        <b-form-group label="Locations" label-for="locationsInput">
          <!-- Use the selectedCity ID as v-model -->
          <b-form-select id="locationsInput" v-model="selectedCity" :options="countryOptions" required multiple></b-form-select>
        </b-form-group>
        <b-form-group label="Fail Threshold" label-for="failThresholdInput">
          <b-form-input id="failThresholdInput" v-model="newWebsite.fail_threshold" required></b-form-input>
        </b-form-group>
        <b-form-group label="Frequency" label-for="frequencyIpInput">
          <b-form-select id="frequencyIpInput" v-model="newWebsite.ping_frequency" :options="frequencys" required></b-form-select>
        </b-form-group>
        <b-form-group label="Type" label-for="typeIpInput">
          <b-form-select id="typeIpInput" v-model="newWebsite.type" :options="types" required></b-form-select>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '../../config/pluginInit'
import axios from 'axios'
import constant from '@/config/constant';
import CryptoJS from 'crypto-js';
export default {
  name: 'UiDataTable',
  data() {
    return {
      columns: [
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'Website', key: 'website', class: 'text-left' },
        { label: 'countries', key: 'countries_name', class: 'text-left' },
        { label: 'Frequency', key: 'ping_frequency', class: 'text-left' },
        { label: 'Fail Threshold', key: 'fail_threshold', class: 'text-left' },
        { label: 'Type', key: 'type', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      countryOptions: [],
      selectedCity: '',
      rows: [], // Initialize the rows array to be empty initially
      modalOpen: false,
      editMode: false,
      newWebsite: {
        name: '',
        website: '',
        sensor_ip: '',
        ping_frequency: '',
        countries: '',
        type: ''
      },
      frequencys: [
        { text: '5', value: '5' },
        { text: '10', value: '10' },
        { text: '20', value: '20' },
        { text: '30', value: '30' }
      ],
      types: [
        { text: 'Http', value: 'Http' },
        { text: 'Https', value: 'Https' }
      ] // // Initialize the Fail Thresholds array
    }
  },
  methods: {
    decryptToken(encryptedToken, key) {
    const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
    const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedToken;
},
async fetchCountryOptions() {
  try {
    const token = localStorage.getItem('token');
    const key = 'enc'; // Fetch encrypted token
    const decryptedToken = this.decryptToken(token, key); // Decrypt token

    const response = await axios.get(constant.API_BASE_URL + 'domain/countries/', {
      headers: {
        'Authorization': 'Token ' + decryptedToken
      }
    });
    
    const countries = response.data.results; // Extract the countries array from the response
    const cityOptions = countries.map(country => ({ text: country.city, value: country.id })); // Map the cities to text-value pairs

    // Update the dropdown options with the cities
    this.countryOptions = cityOptions;
  } catch (error) {
    console.error('Error fetching country options:', error);
  }
},

async fetchData() {
  try {
    const token = localStorage.getItem('token');
    const key = 'enc'; // Fetch encrypted token
    const decryptedToken = this.decryptToken(token, key); // Decrypt token


    const response = await axios.get(constant.API_BASE_URL + 'web-monitoring/web-monitors/', {
      headers: {
        'Authorization': 'Token ' + decryptedToken
      }
    });
    if (response.data && Array.isArray(response.data.results)) {
      this.rows = response.data.results.map(item => ({
        ...item,
        // Directly use countries_name since it's already an array
        countries_name: item.countries_name.join(', ')
      }));
    } else {
      console.error('Error: Unexpected API response structure');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
},

async submitForm() {
  try {
    const token = localStorage.getItem('token');
    const key = 'enc'; // Fetch encrypted token
    
    const decryptedToken = this.decryptToken(token,key); // Decrypt token
    const { name, website, ping_frequency, fail_threshold } = this.newWebsite;

    // Set the selected city into an array
    const countries = this.selectedCity;

    const requestData = {
      name: name,
      website: website,
      countries: countries,
      ping_frequency: ping_frequency,
      fail_threshold: fail_threshold,
      type: this.newWebsite.type, 
    };

    // Set the token in the request headers
    const headers = {
      'Authorization': 'Token ' + decryptedToken
    };

    let response;
    if (this.editMode) {
      // Edit mode
      response = await axios.put(constant.API_BASE_URL + 'web-monitoring/web-monitors/' + this.newWebsite.public_id + '/', requestData, { headers });
    } else {
      // Add mode
      response = await axios.post(constant.API_BASE_URL + 'web-monitoring/web-monitors/', requestData, { headers });
    }

    await this.fetchData();
    this.modalOpen = false;
    this.clearForm();
  } catch (error) {
    console.error('Error adding/editing website:', error);
  }
},
    openModal() {
      this.modalOpen = true;
      this.editMode = false;
      this.clearForm();
    },
    edit(item) {
      this.newWebsite = { ...item };
      this.selectedCity = item.countries.map(country => country.id);
      
      console.log('selectedCity:', this.selectedCity);
      this.modalOpen = true;
      this.editMode = true;
    },
    async remove(public_id) {
      try {
        const token = localStorage.getItem('token');
    const key = 'enc';
    const decryptedToken = this.decryptToken(token, key);
    await axios.delete(constant.API_BASE_URL + 'web-monitoring/web-monitors/' + public_id + '/', {
      headers: {
        'Authorization': 'Token ' + decryptedToken
      }
    });
        await this.fetchData();
      } catch (error) {
        console.error('Error deleting website:', error);
      }
    },
    clearForm() {
      this.newWebsite = {
        name: '',
        website: '',
        sensor_ip: '',
        ping_frequency: '',
        type: '',
        code: ''
      };
    }
  },
  mounted() {
    this.fetchData();
    this.fetchCountryOptions(); 
    core.index();
  }
}
</script>
